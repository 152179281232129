export const menuItems = [

  {
    id: 1,
    label: "menu.menuitems.home.text",
    icon: "bx bxs-home",
    link: '/reports'

  },

  {
    id: 1,
    label: "menu.menuitems.general_settings.text",
    icon: "bx bx-wrench",
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.delivery_pricing.text",
        link: "/general-settings",
      },
      {
        parentId: 28,
        label: "menu.menuitems.tech_support_settings.text",

        subItems: [
          {
            parentId: 28,
            label: "menu.menuitems.tech_support_questions.text",
            link: "/support-questions",
          },
          {
            parentId: 28,
            label: "menu.menuitems.tech_support_status.text",
            link: "/support-status",
          },
        ]
      },

    ]
  },
  {
    id: 1,
    label: "menu.menuitems.service.text",
    icon: "bx bx-food-menu",
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.service.text",
        link: "/services",
      },
      {
        parentId: 28,
        label: "menu.menuitems.mulim_servics.text",
        link: "/mulim-services",
      },

    ]
  },
  {
    id: 1,
    label: "menu.menuitems.commission.text",
    icon: "bx bx-food-menu",
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.settings.text",
        link: "/commissions",
      },
      {
        parentId: 28,
        label: "menu.menuitems.commission_management.text",
        link: "/granted-commission",
      },

    ]
  },
  {
    id: 1,
    label: "menu.menuitems.accounting.text",
    icon: "bx bx-food-menu",
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.withdrawal_request.text",
        link: "/withdraw",
      },
      {
        parentId: 28,
        label: "menu.menuitems.vendors.text",
        link: "/accounting/2",
      },
      {
        parentId: 28,
        label: "menu.menuitems.drivers.text",
        link: "/accounting/3",
      },

    ]
  },
  {
    id: 1,
    label: "menu.menuitems.customers.text",
    icon: "bx bx-food-menu",
    // link: '/customers',
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.customers.text",
        link: "/customers",
      },
      {
        parentId: 28,
        label: "menu.menuitems.orders.text",
        link: "/orders",
      },
     ]

  },
  {
    id: 1,
    label: "menu.menuitems.permission.text",
    icon: "bx-message-dots",
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.banners.text",
        link: '/banners',
      },
    ]


  },
  {
    id: 1,
    label: "menu.menuitems.vendors.text",
    icon: "mdi mdi-account-circle",
    // link: '/vendors'
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.join_request.text",
        link: "/new-vendors",
      },
      {
        parentId: 28,
        label: "menu.menuitems.vendors.text",
        link: "/vendors",
      },
  

    ]

  },


  {
    id: 1,
    label: "menu.menuitems.drivers.text",
    icon: "bx bxs-taxi",
    link: '/',

    subItems: [{
      parentId: 28,
      label: "menu.menuitems.join_request.text",
      link: "/new-driver",
    },
    {
      parentId: 28,
      label: "menu.menuitems.drivers.text",
      link: "/driver",
    },
 
    ]
  }
  ,


  {
    id: 1,
    label: "menu.menuitems.notification_settings.text",
    icon: "bx bxs-notification",
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.notification_settings.text",
        link: '/notification-settings',
      },
      {
        parentId: 28,
        label: "menu.menuitems.public_notifications.text",
        link: '/public-notification',
      },
     

    ]

  },

  {
    id: 1,
    label: "menu.menuitems.tech-support.text",
    icon: "bx bx-chat",
    // link: '/tech-support'
    subItems: [
      {
        parentId: 28,
        label: "menu.menuitems.support_requests.text",
        link: "/support-requests",
      },
    ]

  },
  {
    id: 1,
    label: "menu.menuitems.users.text",
    icon: "mdi mdi-account-circle",
    link: '/users'

  },



];

