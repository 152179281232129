// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth"; // Import the Auth module

// Replace with your actual Firebase configuration
// const firebaseConfig  = {
//    apiKey: "AIzaSyB_Y80JBts_l9QsLvgH_1L0I-9m9SDic6w",
//     authDomain: "mulim-app.firebaseapp.com",
//     projectId: "mulim-app",
//     storageBucket: "mulim-app.appspot.com",
//     messagingSenderId: "1098357395066",
//     appId: "1:1098357395066:web:af6dc4c41df33877a3b5c3",
//     measurementId: "G-0SYXX2W7NV"
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app); // Get the Auth instance
const auth = () => {}; // Get the Auth instance
export default auth;
