<script>
// import axios from "axios";

import { authComputed } from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  name: "nav-bar",
  data() {
    return {
      currentUser: { vendors: [] },
      is_notification: false,
      lang: "",
      text: null,
      flag: null,
      value: null,
      sideNavHide: false,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "عربي",
        },
      ],
    };
  },
  created() {
    if (!localStorage.lang) return (this.lang = "ar");
    this.lang = "en";
  },
  mounted() {
    if (localStorage.currentUser) {
      this.currentUser = JSON.parse(localStorage?.currentUser);
      console.log({ currentUser: this.currentUser });
    }
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
      // var main_content = document.querySelector(".main-content")
      // var side_menu = document.getElementById("sidebar-menu")
      if (this.sideNavHide == false && this.$i18n.locale == "ar") {
        // main_content.style.marginRight = "70px"
        this.sideNavHide = true;
        console.log(this.sideNavHide);
        // side_menu.style.position = "relative !important"
        // side_menu.style.left = "38px"
      } else if (this.sideNavHide == true && this.$i18n.locale == "ar") {
        console.log(this.$i18n.locale);
        // main_content.style.marginRight = "250px"
        this.sideNavHide = false;
      } else if (this.sideNavHide == false && this.$i18n.locale == "en") {
        // main_content.style.marginLeft = "70px"
        this.sideNavHide = true;
        console.log(this.sideNavHide);
      } else if (this.sideNavHide == true && this.$i18n.locale == "en") {
        console.log(this.$i18n.locale);
        // main_content.style.marginLeft = "250px"
        this.sideNavHide = false;
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    // logoutUser() {
    //   // eslint-disable-next-line no-unused-vars
    //   axios.get("http://127.0.0.1:8000/api/logout").then((res) => {
    //     this.$router.push({
    //       name: "default",
    //     });
    //   });
    // },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header" style="padding: 0">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- <img
            src="../assets/logo.png"
            style="width: 50px; height: 50px"
            alt=""
          /> -->
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
      </div>

      <!-- <div class="d-flex">
        <router-link to="/verify"
          ><button class="btn btn-secondary">
            {{ $t("home.not_verified") }}
          </button></router-link
        >
      </div> -->

      <div
        class="d-flex"
        style="
          margin-inline-end: 1rem;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div>
          <img
            style="
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 1px gray solid;
            "
            :src="
              $store.state?.auth?.mediaUrl +
              currentUser?.vendors[0]?.image?.split('public')[1]
            "
            alt=""
          />
          <span class="mx-2">
            {{ $t("home.hello") }} {{ currentUser?.name?.split(" ")[0] }}</span
          >

          <div class="position-relative d-inline-block">
            <a
              @click="is_notification = !is_notification"
              type="button"
              class="mx-4"
            >
              <span class="badge bg-danger text-light rounded-pill">2</span>
              <!-- <i class="bx bxs-bell text-danger fa-2x"></i> -->
              <i
                :class="{ 'bx-tada': is_notification }"
                class="bx bx-bell fa-2x text-danger"
              ></i>
            </a>
            <div
              v-if="is_notification"
              style="
                background-color: rgb(232 232 239);
                position: absolute;
                top: 45px;
                left: 0px;
                z-index: 100;
                width: 300px;
                min-height: 150px;
                max-height: calc(100vh - 208px);
                border-radius: 0.24rem;
                box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.3);
                overflow-y: scroll;
              "
            >
              <ol style="padding: 8px" class="list-group">
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2">
                    <div class="fw-bold">New Order</div>
                    you've new order
                  </div>
                  <span
                    class="bx bx-circle text-danger bg-danger"
                    style="border-radius: 50%; border: 2px"
                  ></span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2">
                    <div class="fw-bold">New Order</div>
                    you've new order
                  </div>
                  <span
                    class="bx bx-circle text-danger bg-danger"
                    style="border-radius: 50%; border: 2px"
                  ></span>
                </li>
              </ol>
            </div>
          </div>
          <div class="position-relative d-inline-block">
            <a
              @click="is_notification = !is_notification"
              type="button"
              class="mx-4"
            >
              <span class="badge bg-danger text-light rounded-pill">1</span>
              <!-- <i class="bx bxs-bell text-danger fa-2x"></i> -->
              <i
                :class="{ 'bx-tada': is_notification }"
                class="bx bx-cart fa-2x text-danger"
              ></i>
            </a>
            <div
              v-if="is_notification"
              style="
                background-color: rgb(232 232 239);
                position: absolute;
                top: 45px;
                left: 0px;
                z-index: 100;
                width: 300px;
                min-height: 150px;
                max-height: calc(100vh - 208px);
                border-radius: 0.24rem;
                box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.3);
                overflow-y: scroll;
              "
            >
              <ol style="padding: 8px" class="list-group">
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2">
                    <div class="fw-bold">New Order</div>
                    you've new order
                  </div>
                  <span
                    class="bx bx-circle text-danger bg-danger"
                    style="border-radius: 50%; border: 2px"
                  ></span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2">
                    <div class="fw-bold">New Order</div>
                    you've new order
                  </div>
                  <span
                    class="bx bx-circle text-danger bg-danger"
                    style="border-radius: 50%; border: 2px"
                  ></span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <select
          v-model="$i18n.locale"
          style="color: #2a3042"
          class="lang-dropdown border-0 m-0"
        >
          <option
            @click="changeLanguage(locale)"
            v-for="(locale, i) in languages"
            :key="`locale-${i}`"
            :value="locale.language"
          >
            {{
              locale.title ? locale.title : lang == "en" ? "English" : "Arabic"
            }}
          </option>
        </select>
        <div class="inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="logout"
          >
            <i
              :class="$i18n.locale == 'en' ? 'bx-log-in' : 'bx-log-out'"
              class="bx text-danger fa-2xl"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { watch, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();

const store = useStore();

onMounted(() => {
  companies.value = store.state.authfack.companies;
  selected_company.value = store.state.authfack.selected_company;
  selected_company.value = filterComps()[0];
});

const i18n = useI18n();
watch(
  () => i18n.locale.value,
  (newVal) => {
    localStorage.lang = newVal;
    if (localStorage.lang == "ar") {
      document.querySelector("html").setAttribute("dir", "rtl");
      document.querySelector("html").setAttribute("lang", "ar");
      // document.body.classList.add("ar")
    } else {
      document.querySelector("html").setAttribute("dir", "ltr");
      document.querySelector("html").setAttribute("lang", "en");
      // document.body.classList.remove("ar")
    }
  }
);

const logout = () => {
  localStorage.removeItem("currentUser");
  router.replace("/login");
};

const companies = ref([]);

const filterComps = () => {
  return companies.value.filter((c) => {
    if (
      store?.state?.authfack?.permissions[c] &&
      Object.keys(store?.state?.authfack?.permissions[c]).length > 0
    )
      return c;
  });
};
const selected_company = ref("");

watch(
  () => selected_company.value,
  (newVal) => {
    store.commit("authfack/setSelectedCompany", newVal);
    console.log({
      selected_company: store.state.authfack.selected_company,
    });
  }
);
</script>
